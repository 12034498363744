import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { BSLEmploymentStatus } from '@cbo/shared-library';

import {
  UpdateEmployeeContactInfoRequestSchema,
  ChangeJobActivationStatusDto,
  UpdateEmployeeEmergencyContactRequestSchema,
  UpdateEmployeeCertificationSchema,
  UpdateEmployeeDetailsSchema,
  UpdateEmployeePersonalInfoRequestSchema,
  UpdateJobDto,
  UpdateResponse,
  UpdateEmployeeCertificationInfoSchema,
  EmployeeContactDetailsInformation,
  EmploymentHistoryIdResponse,
  UpdateEmployeeRequestSchema,
  UpdateEmployeeHistorySchema,
  JobProfile,
  UpdateJobProfileDto,
  CreateSelfEmployeeRequestSchema,
  CreateSelfEmployeeResponse,
  UpdateUserStatusResponse,
  CreateEmployeeResponse,
  CreateEmployeeRequestSchema,
} from '../types';
import useLaborRequests from './index';

export const useUpdateContactInformationMutation = (): UseMutationResult<
  UpdateResponse | void,
  unknown,
  { employeeId: string; contactInfo: EmployeeContactDetailsInformation }
> => {
  const { updateContactInformation } = useLaborRequests();
  return useMutation(
    (updatePersonalInfoVariables: { employeeId: string; contactInfo: EmployeeContactDetailsInformation }) =>
      updateContactInformation(updatePersonalInfoVariables.employeeId, updatePersonalInfoVariables.contactInfo),
    {
      useErrorBoundary: false,
    }
  );
};

export const useUpdateEmployeeDetailsMutation = (): UseMutationResult<
  UpdateResponse | void,
  unknown,
  { employeeId: string; employeeDetails: UpdateEmployeeDetailsSchema }
> => {
  const { updateEmployeeDetails } = useLaborRequests();
  return useMutation(
    (updateEmployeeBirthdateVariables: { employeeId: string; employeeDetails: UpdateEmployeeDetailsSchema }) =>
      updateEmployeeDetails(
        updateEmployeeBirthdateVariables.employeeId,
        updateEmployeeBirthdateVariables.employeeDetails
      ),
    {
      useErrorBoundary: true,
    }
  );
};

export const useUpdateEmployeeCertificationMutation = (
  employeeId: string,
  certificationId?: string | null
): UseMutationResult<UpdateResponse | void, unknown, UpdateEmployeeCertificationInfoSchema> => {
  const { updateEmployeeCertification, createEmployeeCertification } = useLaborRequests();
  return useMutation(
    (certification: UpdateEmployeeCertificationInfoSchema) =>
      certificationId
        ? updateEmployeeCertification(employeeId, certificationId, certification)
        : createEmployeeCertification(employeeId, certification),
    {
      useErrorBoundary: true,
    }
  );
};

export const useUpdateEmployeeMutation = (
  employeeId: string
): UseMutationResult<UpdateResponse | void, unknown, UpdateEmployeeRequestSchema> => {
  const { updateEmployee } = useLaborRequests();

  return useMutation(
    (updateEmployeeVariables: UpdateEmployeeRequestSchema) => updateEmployee(employeeId, updateEmployeeVariables),
    { useErrorBoundary: false }
  );
};

export const useCreateEmploymentHistoryMutation = (): UseMutationResult<
  EmploymentHistoryIdResponse | void,
  unknown,
  { employeeId: string; employmentHistory: UpdateEmployeeHistorySchema }
> => {
  const { createEmploymentHistory } = useLaborRequests();
  return useMutation(
    (createEmploymentHistoryPayload: { employeeId: string; employmentHistory: UpdateEmployeeHistorySchema }) =>
      createEmploymentHistory(
        createEmploymentHistoryPayload.employeeId,
        createEmploymentHistoryPayload.employmentHistory
      )
  );
};

export const useUpdateEmploymentHistoryMutation = (): UseMutationResult<
  void,
  unknown,
  { employeeId: string; employmentHistoryId: string; employmentHistory: UpdateEmployeeHistorySchema }
> => {
  const { updateEmploymentHistory } = useLaborRequests();
  return useMutation(
    (updateEmploymentHistoryPayload: {
      employeeId: string;
      employmentHistoryId: string;
      employmentHistory: UpdateEmployeeHistorySchema;
    }) =>
      updateEmploymentHistory(
        updateEmploymentHistoryPayload.employeeId,
        updateEmploymentHistoryPayload.employmentHistoryId,
        updateEmploymentHistoryPayload.employmentHistory
      )
  );
};

export const useUpdateSelfEmployeeInformationMutation = (): UseMutationResult<
  UpdateResponse | void,
  unknown,
  | UpdateEmployeeEmergencyContactRequestSchema
  | UpdateEmployeeContactInfoRequestSchema
  | UpdateEmployeePersonalInfoRequestSchema
> => {
  const { updateSelfEmployeeInfo } = useLaborRequests();
  return useMutation(
    (
      employeeInformation:
        | UpdateEmployeeEmergencyContactRequestSchema
        | UpdateEmployeeContactInfoRequestSchema
        | UpdateEmployeePersonalInfoRequestSchema
    ) => updateSelfEmployeeInfo(employeeInformation),
    { useErrorBoundary: false }
  );
};

export const useUpdateSelfEmployeeCertificationMutation = (
  certificateId: string
): UseMutationResult<UpdateResponse | void, unknown, UpdateEmployeeCertificationInfoSchema> => {
  const { updateSelfEmployeeCertification, createSelfEmployeeCertification } = useLaborRequests();
  return useMutation(
    (certification: UpdateEmployeeCertificationSchema) =>
      certificateId
        ? updateSelfEmployeeCertification(certification, certificateId)
        : createSelfEmployeeCertification(certification),
    {
      useErrorBoundary: true,
    }
  );
};

export const useUpdateEmployeeUserStatusMutation = (): UseMutationResult<
  UpdateUserStatusResponse | void,
  unknown,
  { username: string; status: BSLEmploymentStatus }
> => {
  const { updateUserStatus } = useLaborRequests();
  return useMutation((updateUserStatusVariables: { username: string; status: BSLEmploymentStatus }) =>
    updateUserStatus(updateUserStatusVariables.username, updateUserStatusVariables.status)
  );
};

export const useUpdateJobMutation = (): UseMutationResult<
  void,
  unknown,
  { jobId: string; updateJobDto: UpdateJobDto },
  unknown
> => {
  const { updateJob } = useLaborRequests();

  return useMutation(
    (updateJobcodeVariables: { jobId: string; updateJobDto: UpdateJobDto }) =>
      updateJob(updateJobcodeVariables.jobId, updateJobcodeVariables.updateJobDto),
    {
      useErrorBoundary: true,
    }
  );
};

export const useChangeJobActivationStatusMutation = (): UseMutationResult<
  void,
  unknown,
  { jobId: string; changeJobActivationDto: ChangeJobActivationStatusDto },
  unknown
> => {
  const { changeJobActivation } = useLaborRequests();

  return useMutation(
    (changeJobActivationVariables: { jobId: string; changeJobActivationDto: ChangeJobActivationStatusDto }) =>
      changeJobActivation(changeJobActivationVariables.jobId, changeJobActivationVariables.changeJobActivationDto),
    {
      useErrorBoundary: true,
    }
  );
};

export const useCreateJobProfileMutation = (): UseMutationResult<string | void, unknown, JobProfile, unknown> => {
  const { createJobProfile } = useLaborRequests();
  return useMutation((jobProfile: JobProfile) => createJobProfile(jobProfile), {
    useErrorBoundary: true,
  });
};

export const useUpdateJobProfileMutation = (): UseMutationResult<
  string | void,
  unknown,
  { jobProfileId: string; updateJobProfileDto: UpdateJobProfileDto },
  unknown
> => {
  const { updateJobProfile } = useLaborRequests();
  return useMutation(
    (updateJobProfileVariables: { jobProfileId: string; updateJobProfileDto: UpdateJobProfileDto }) =>
      updateJobProfile(updateJobProfileVariables.jobProfileId, updateJobProfileVariables.updateJobProfileDto),
    {
      useErrorBoundary: true,
    }
  );
};

export const useDeleteJobProfileMutation = (): UseMutationResult<
  string | void,
  unknown,
  {
    jobProfileId: string;
  },
  unknown
> => {
  const { deleteJobProfile } = useLaborRequests();
  return useMutation(({ jobProfileId }) => deleteJobProfile(jobProfileId), {
    useErrorBoundary: true,
  });
};

export const useCreateSelfEmployeeMutation = (): UseMutationResult<
  CreateSelfEmployeeResponse | void,
  unknown,
  CreateSelfEmployeeRequestSchema,
  unknown
> => {
  const { createSelfEmployee } = useLaborRequests();
  return useMutation((employee: CreateSelfEmployeeRequestSchema) => createSelfEmployee(employee), {
    useErrorBoundary: true,
  });
};

export const useCreateEmployeeMutation = (): UseMutationResult<
  CreateEmployeeResponse | void,
  unknown,
  CreateEmployeeRequestSchema,
  unknown
> => {
  const { createEmployee } = useLaborRequests();
  return useMutation((employee: CreateEmployeeRequestSchema) => createEmployee(employee), {
    useErrorBoundary: true,
  });
};
